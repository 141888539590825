import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import Logo from '../../Assests/progear_new.svg';

class Contact extends React.Component {
    handleClickPhone = () => {
        window.open('tel:+919094008000', '_self');
    };

    handleClickEmail = () => {
        window.open('mailto:support@progearautomotive.com', '_self');
    };

    render() {
        return (
            <div className='contact'>
                <span className='contactProGear' onClick={this.handleClickPhone}><FaPhone /> +91 9094008000</span>
                <span className='contactProGear' onClick={this.handleClickEmail}><IoMail /> support@progearautomotive.com </span>
                <div className='socialmediaicon'>
                    <a href='https://www.facebook.com/progearcars' target="_blank" rel="noreferrer"><FaFacebookSquare /></a>
                    <a href='https://www.instagram.com/progearcars' target="_blank" rel="noreferrer"><FaInstagram /></a>
                    <a href='https://www.linkedin.com/company/ProGearautomotive/' target="_blank" rel="noreferrer"><FaLinkedin /></a>
                    <a href='https://x.com/progearcars?t=LerHw5BM53nhRf2KDPJkXQ&s=09' target="_blank" rel="noreferrer"><FaTwitter /></a>
                </div>
            </div>
        );
    }
}

class Footer extends React.Component {
    handleNavItemClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    render() {
        return (
            <>
            <section>
                <div className='footer'>
                    <div className='footercontent'>
                        <div className='socialmedia'>
                            <div className='footertitle'>
                                <img src={Logo} alt="ProGear Automotive" className='logo' />
                                <span>Get the best of both worlds with ProGear : Lease brand new and pre-owned cars.</span>
                            </div>
                        </div>
                    </div>
                    <div className='footercontent'>
                        <div className='footerdetails'>
                            <span>USEFUL LINKS</span>
                            <div className='footerdetailslist'>
                                <Link to="/about-us" onClick={this.handleNavItemClick}>About Us</Link>
                                <Link to="/corporate-lease" onClick={this.handleNavItemClick}>Corporate Lease</Link>
                                <Link to="/privacy-policy" onClick={this.handleNavItemClick}>Privacy Policy</Link>
                                <Link to="/terms-and-conditions" onClick={this.handleNavItemClick}>Terms & Conditions</Link>
                                <Link to="/corporate-faq" onClick={this.handleNavItemClick}>Corporate FAQs</Link>
                                <Link to="/faq" onClick={this.handleNavItemClick}>Retails FAQs</Link>
                            </div>
                        </div>
                    </div>
                    <Contact />
                </div>
                <div className='FooterCpy'>©2024 ProGearAutomotive.com</div>
                </section>
            </>
        );
    }
}

export default Footer;
