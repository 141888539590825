import React, { memo } from 'react'
import carimg from '../../Assests/subscriptionImg.png';
import './Subscription.scss';
// import Findtherightcar from '../../Assests/findtherightcar.png'
// import Driveworry from '../../Assests/drivewrroy.png';

const Howitworks = memo(() => {

  return (
    <>
      <section>
        <div className='Container'>
          <div className='mainTitle'>
            <span className='content'>ProGear Automotive's</span>
            <span className='title'>Hassle-free car leasing and subscription plans!</span>
            <span className='Description'>Prices vary and is completely negotiable</span>
          </div>
          <div className='howsubscriptionwork'>
            <div className='contentDescription'>
              <div className='types'>
                <div className='details'>
                  <span className='Note'>Explore</span>
                  <span className='detailstitle'><span className='subspan'>Starting at just  </span>₹30k <span className='subspan'>onwards / month</span></span>
                  <span className='detailsdescription'>Security Deposit: <span className='Security_Deposit'>Equivalent to 2 months of subscription cost</span></span>
                </div>
                <div className='details'>
                  <span className='Note'>Explore + Save</span>
                  <span className='detailstitle'><span className='subspan'>As low as  </span>₹15k <span className='subspan'>onwards / month</span></span>
                  <span className='detailsdescription'>Security Deposit: <span className='Security_Deposit'>₹3 lakh onwards</span></span>
                </div>
              </div>
            </div>
            <div className='contentImage'>
              <img src={carimg} alt="carimg" className='carimg' />
            </div>
          </div>
          <span className='content'>* Terms and conditions apply</span>
        </div>
      </section>
    </>
  )
})

export default Howitworks