import React, { memo } from 'react'
import OutsetBanner from '../../Assests/OutsetBanner.png';
import './Benifites.scss';
import Downpayment from '../../Assests/Downpayment.png';
import WhiteNumberplate from '../../Assests/Whitenumberplate.png';
import Service from '../../Assests/Service.png';
import Tax from '../../Assests/Tax.png';
import B2B from '../../Assests/B2B.png';
import RSA from '../../Assests/RSA.png';
import NewCar from '../../Assests/NewCar.png';

const Benifites = memo(() => {
  return (
    <>
        <section>
            <div className='benifites'>
                <img src={OutsetBanner} className='outsetbanner' alt='CPLBanner'/>
                <div className='Benifiteslayout'>
                    <div className='BenifiteslayoutContent'>
                        <span className='text1'>What Is All-Inclusive</span>
                        <span className='text2'>Benefits For Employer and Employee</span>
                        <span className='text3'>Progear offers a smooth and seamless corporate car subscription service throughout India, delivering high-quality certified pre-owned and new cars for the businesses.</span>
                    </div>
                    <div className='benifitesDetails'>
                        <div className='benifitesDetailstable'>
                            <span>Employer Benefits</span>
                            <div className='benifitesDetailselement'>
                                <img src={Downpayment} alt='Cost Saving'/>
                                <span>Cost Saving</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={WhiteNumberplate} alt='Flexibility – Vehicle selection & change'/>
                                <span>Flexibility – Vehicle selection & change</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={Service} alt='Proven talent attraction and retention tool'/>
                                <span>Proven talent attraction and retention tool</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={Tax} alt='Tax Benifites: saves additional 30% on taxes'/>
                                <span>Tax Benefits: saves additional 30% on taxes</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={RSA} alt='Employee Satisfaction'/>
                                <span>Employee Satisfaction</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={B2B} alt='Enhanced Corporate'/>
                                <span>Enhanced Corporate </span>
                            </div>
                        </div>
                        <div className='benifitesDetailstable'>
                            <span>Employee Benefits</span>
                            <div className='benifitesDetailselement'>
                                <img src={Downpayment} alt='Cost Saving & Efficient'/>
                                <span>Cost Saving & Efficient</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={NewCar} alt='Access to newer vehicles'/>
                                <span>Access to newer vehicles</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={Tax} alt='Tax Benifites: saves 30% on taxes'/>
                                <span>Tax Benefits: saves 30% on taxes</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={RSA} alt='Enhanced work-life balance'/>
                                <span>Enhanced work-life balance</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={B2B} alt='No burden of long-time commitment'/>
                                <span>No burden of long-time commitment</span>
                            </div>
                            <div className='benifitesDetailselement'>
                                <img src={WhiteNumberplate} alt='Convenience of transport'/>
                                <span>Convenience of transport</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
})

export default Benifites