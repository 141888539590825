import React, { memo } from 'react';
import GeneralTerms from '../../Components/GeneralTerms/General';
import { TeamsandConditionDetails } from './TermsandConditionDetails.js';
import { Helmet } from 'react-helmet';

const Terms = memo(() => {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Terms and Conditions | ProGear </title>
          <meta name="description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!"></meta>

          {/* SEO meta tags */}
          <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
          <meta name="author" content="ProGear Automotive Pvt Ltd" />

          {/* Mobile-specific meta tags */}
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="theme-color" content="#ffffff" />

          {/* Open Graph meta tags for social sharing */}
          <meta property="og:title" content="Terms and Conditions | ProGear "/>
          <meta property="og:description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!" />
          <meta property="og:image" content="../../../public/favicon.ico" />
          <meta property="og:url" content="https://www.progearautomotive.com/" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://www.progearautomotive.com/terms-and-conditions/"></link>
        </Helmet>
    <section>
    <div className='Policy'>
      <GeneralTerms title='Terms and Conditions' details={TeamsandConditionDetails} />
    </div>
  </section>
  </>
  )
})

export default Terms