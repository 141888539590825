import React, { memo } from 'react'
import carimg from '../../Assests/carimg.png';
import Findtherightcar from '../../Assests/findtherightcar.png'
import Driveworry from '../../Assests/drivewrroy.png';
import Approval from '../../Assests/approval.png';
import './Howitworks.scss';
const Howitworks = memo(() => {
  const howItWorkData = [
    {
      icon: Findtherightcar,
      title: "Choose a car",
      text: "Choose the right car among our quality approved cars"
    },
    {
      icon: Approval,
      title: "Fill the details and discuss",
      text: "Need to discuss & submit required documents for approval"
    },
    {
      icon: Driveworry,
      title: "Pay and take up your safe journey",
      text: "Enjoy your journey like you own the car"
    }
  ];
  return (
    <>
      <section>
        <div className='howitwork'>
          <div className='contentDescription'>
            <div className='howitworktext'>
              <span className='howitworkstitle'>3 simple steps to own a car</span>
              <span className='howitworkoutsettext'>Simplified for your convenience at ProGear</span>
            </div>
            <div className='howitworklistcontent'>
              {howItWorkData.map((item, index) => (
                <div className='howitworklist' key={index}>
                  <img src={item.icon} alt={`${item.title}`} className='iconimg'></img>
                  <div className='howitworklisttext'>
                    <span className='howitworklisttitle'>{item.title}</span>
                    <span className='howitworkoutsettext'>{item.text}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='contentImage'>
            <img src={carimg} alt="carimg" className='carimg' />
          </div>
        </div>
      </section>
    </>
  )
})

export default Howitworks