import React, { memo } from 'react'
import './Explore.scss'
const Explore = memo(() => {
  return (
    <section className='explor'>
      <span className='title'>Our Exclusive Plans</span>
      <div className='types'>
        <div className='details'>
          <span className='Note'>Explore</span>
          <span className='detailstitle'><span className='subspan'>Starting at just  </span>₹30k <span className='subspan'>onwards / month</span></span>
          <span className='detailsdescription'>Security Deposit: <span className='Security_Deposit'>Equivalent to 2 months of subscription cost</span></span>
        </div>
        <div className='details'>
        <span className='Note'>Explore + Save</span>
          <span className='detailstitle'><span className='subspan'>As low as  </span>₹15k <span className='subspan'> onwards / month</span></span>
          <span className='detailsdescription'>Security Deposit: <span className='Security_Deposit'>₹3 lakh onwards</span></span>
        </div>
      </div>
    </section>
  )
})

export default Explore