import React, { memo } from 'react'
import './AboutUs.scss';
import { Helmet } from 'react-helmet';
import AboutBanner from '../../Components/AboutBannerContent/AboutBanner';
// import MVV from '../../Components/MVV/MVV.jsx';
import Explore from '../../Components/Explore/Explore.jsx'
import MissionVisionValue from '../../Components/MVV2/MVV2';
import USP from '../../Components/USP/USP.jsx'

const AboutUs = memo(() => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | ProGear car subscription in Chennai | 0% down payment</title>
                <meta name="description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!"></meta>

                {/* SEO meta tags */}
                <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
                <meta name="author" content="ProGear Automotive Pvt Ltd" />

                {/* Mobile-specific meta tags */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="theme-color" content="#ffffff" />

                {/* Open Graph meta tags for social sharing */}
                <meta property="og:title" content="About Us | ProGear car subscription in Chennai | 0% down payment" />
                <meta property="og:description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!" />
                <meta property="og:image" content="../../../public/favicon.ico" />
                <meta property="og:url" content="https://www.progearautomotive.com/" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://www.progearautomotive.com/about-us/"></link>
            </Helmet>
            <section>
                <AboutBanner
                    text1='How It All Began?'
                    text2='The ProGear Automotive Story'
                    text3="Founded by Mr. Silambarasan ProGear Automotive began as a multibranded car service workshop However, Mr. Silambarasan's vision extended beyond routine repairs. Drawing from his hands-on experience he introduced a groundbreaking concept: car leasing and subscription. Today, ProGear Automotive offers flexible leasing solutions, revolutionizing the way people interact with cars."
                    text4="Discover the freedom of car leasing with ProGear Automotive!"
                />
                <USP />
                {/* <MVV /> */}
                <MissionVisionValue />
                <Explore />
                
            </section>
        </>
    )
})

export default AboutUs;