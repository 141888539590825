import React, { memo } from 'react';
import FAQ from '../../Components/Accordian/FAQ';
import { Helmet } from 'react-helmet';
import { menuItems, accordionData } from '../../Components/Accordian/FAQData';
import './FAQ.scss'
const FAQPage = memo(() => {
  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ - Car Subscription Service in Chennai - Progear</title>
          <meta name="description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!"></meta>

          {/* SEO meta tags */}
          <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
          <meta name="author" content="ProGear Automotive Pvt Ltd" />

          {/* Mobile-specific meta tags */}
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="theme-color" content="#ffffff" />

          {/* Open Graph meta tags for social sharing */}
          <meta property="og:title" content="FAQ - Car Subscription Service in Chennai - Progear"/>
          <meta property="og:description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!" />
          <meta property="og:image" content="../../../public/favicon.ico" />
          <meta property="og:url" content="https://www.progearautomotive.com/" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://www.progearautomotive.com/faq/"></link>
        </Helmet>
      <div className='faq'>
        <FAQ faqsTitle="Frequently Asked Questions" menuItems={menuItems} accordionData={accordionData} />
      </div>
    </>
  );
});

export default FAQPage;
