import React, { memo } from 'react';
import './BTN.scss';

const BTN = memo(({ buttonText }) => {
  return (
    <button className='btn'>{buttonText}</button>
  );
});

export default BTN;
