import React from 'react';
import '../Savemoney/Savemoney.scss';
import { offerFeatures1Table, offerFeatures2Table, offerFeatures3Table, offerFeatures4Table, offerFeatures5Table } from './LeaseFeature';

const OfferTable = ({ tableId }) => {
    let offerFeatures;
    switch (tableId) {
        case 1:
            offerFeatures = offerFeatures1Table;
            break;
        case 2:
            offerFeatures = offerFeatures2Table;
            break;
        case 3:
            offerFeatures = offerFeatures3Table;
            break;
        case 4:
            offerFeatures = offerFeatures4Table;
            break;
        case 5:
            offerFeatures = offerFeatures5Table;
            break;
        default:
            offerFeatures = [];
    }
    return (
        <div className='tableContainar'>
            <table className='table'>
                <thead>
                    <tr>
                        <th className='feature'></th>
                        <th className='pros'>ProGear Subscription</th>
                        <th className='cons'>Buy</th>
                    </tr>
                </thead>
                <tbody>
                    {offerFeatures.map((feature, index) => (
                        <tr key={feature.id} style={index === offerFeatures.length - 1 ? { backgroundColor: 'black', color: 'white', fontWeight:'bold' } : null}>
                            <td className='leftalign'>{feature.feature}</td>
                            <td>{feature.pros}</td>
                            <td>{feature.cons}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OfferTable;
