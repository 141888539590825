export const menuItems = [
  "Company",
  "Finance",
  "Legal",
  "Employee",
];
export const accordionData = [
    {
      category: 'Company',
      title: 'How easy reciprocal to launch car leasing program for our company?',
      content: `We guide our customer through out the process from customizing to launching the car policy in their company based on their needs.`,
    },
    // {
    //   category: 'Company',
    //   title: 'What are the benefits of ProGear car subscription program?',
    //   content: <ul><li>No depreciation risks</li>
    //   <li>No maintenance and service issue</li>
    //   <li>No insurance and tax pay</li>
    //   <li>Flexible tenures</li>
    //   <li>Stay up with the latest cars</li></ul>,
    // },
    {
      category: 'Company',
      title: 'Who is responsible for traffic challans incurred during lease period?',
      content: `During the lease duration, the User has full responsibility for all Challans and violations. But, we will pay the challans on the employee's behalf and include them in the next invoice for your convenience.`,
    },
    {
      category: 'Company',
      title: 'Who will own the vehicles? ',
      content: `Progear Automotive / ProGear Partner will own the vehicles. `,
    },
    {
      category: 'Company',
      title: 'What happens if the car meets with the major problems?',
      content:"As the car is insured, do not worry about the car. Ensuring the user's safety is our primary concern."
    },
    {
      category: 'Finance',
      title: 'How does the billing work in leasing program?',
      content: `A monthly invoice will be generated by progear in the name of the company.`,
    },
    {
      category: 'Legal',
      title: 'How complicated is legal process?',
      content: `Simple, clear, and low-risk for your business, our basic Master Lease Agreement is signed between organizations. `,
    },
    {
      category: 'Legal',
      title: 'What happens if the employee leaves the job within the lease tenure?',
      content: `In this case, the employee may carry out the Lease in his or her individual capacity. In this instance, ProGear Automotive's retail lease policy will be in effect. Alternatively, he may give up the vehicle in accordance with our accommodating foreclosure plan. In addition, the lease may be transferred to the new business if the employee is leaving to continue receiving tax benefits.`,
    },
    {
      category: 'Employee',
      title: 'Can our employees take the car for any amount?',
      content:`Yes, this is entirely dependent on the employee's eligibility and your company's automobile policy.`
    },    
    {
      category: 'Employee',
      title: 'What is covered under monthly lease rental?',
      content: `It is an all inclusive car subscription which includes End to End Maintenance, Servicing, Insurance, 24X7 RSA, Warranty of mechanical parts, GST, free Pick up and drop.`,
    },
    {
      category: 'Employee',
      title: 'Is there is any lockin period?',
      content: `Yes, there is a lock-in period that varies based on the user's selected vehicle and your company's credit assessment.`,
    },
    {
      category: 'Employee',
      title: 'What are the available car models?',
      content: `With the user chosen models, you may easily pick any make and model of car according to your preference.`,
    },
    {
      category: 'Employee',
      title: 'Can I choose my own car, will ProGear purchase and lease it to me ?',
      content: `Yes, you are free to select any car you like. But first, it will be thoroughly inspected, and only then will the car be rented if all the requirements are met.`,
    },
    {
      category: 'Employee',
      title: 'Can the car be driven by other family member?',
      content: `Yes, if they hold proper valid driving license.`,
    },
]