import React, { memo } from 'react';
import './AboutBanner.scss';

const AboutBanner = memo(({ text1, text2, text3, text4 }) => {
  return (
    <>
      <section>
        <div className='AboutBanner'>
          <div className='bannertextlayout'>
            <div className='bannerlayout'>
              <span className='bannertext1' >{text1}</span>
              <span className='bannertext2'>{text2}</span>
            </div>
            <div className='bannerlayout'>
              <span className='bannertext3'>{text3}</span>
              <span className='bannertext4'>{text4}</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default AboutBanner;