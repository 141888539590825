import React from 'react';
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

const offerFeatures = [
    { id: 1, feature: 'Zero Down Payment & Road Tax', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 2, feature: 'No Financing Process', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 3, feature: 'Cheaper than EMI', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 4, feature: 'Annual Insurance Included', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 5, feature: 'Free Car Service & Maintenance', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 6, feature: 'Free 24x7 All India Road Side Assistance', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 7, feature: 'End to End Warranty', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 8, feature: 'Hassle Free Insurance Claims', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 9, feature: 'Stress Free Car Maintenance', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 10, feature: 'Wide Range of ProGear Certified Cars to Choose', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 11, feature: 'Flexible Tenure & Easy Extension Process', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
    { id: 12, feature: 'No Dealer Visit at Time of Purchase/Sale', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaXmark style={{ color: "#A53D3D" }} /> },
];

export default offerFeatures;
