import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../Components/Banner/Banner';
import OutsetBanner from '../../Components/OutsetBanner/OutsetBanner';
import Howitworks from '../../Components/Howitworks/Howitworks';
import Savemoney from '../../Components/Savemoney/Savemoney';
import Partners from '../../Components/Partners/Partners';
import HomeFAQ from '../../Components/Accordian/HomeFAQ';
import HomeBanner from '../../Assests/HomeBanner.png';
import HomeBanner1 from '../../Assests/HomeBanner_md.svg';
import outsetBanner from '../../Assests/OutsetBanner.png';
import Downpayment from '../../Assests/Downpayment.png';
import WhiteNumberplate from '../../Assests/Whitenumberplate.png';
import Service from '../../Assests/Service.png';
import Tax from '../../Assests/Tax.png';
import B2B from '../../Assests/B2B.png';
import RSA from '../../Assests/RSA.png';
import Finance from '../../Assests/Finance.png';
import Flexible from '../../Assests/Flexible.png';
import { accordionData } from '../../Components/Accordian/MostFAQHome';
import Subscription from '../../Components/Subscription/Subscription'
const Home = memo(() => {
  const leftContentData = [
    { icon: Downpayment, text: "Zero Down-Payment" },
    { icon: WhiteNumberplate, text: "White Number Plate" },
    { icon: Service, text: "Service & Maintenance" },
    { icon: Tax, text: "Tax Benefits" }
  ];

  const rightContentData = [
    { icon: Flexible, text: "Flexible Tenure" },
    { icon: B2B, text: "B2B Insurance" },
    { icon: RSA, text: "24x7 RSA" },
    { icon: Finance, text: "Finance Support" }
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ProGear car subscription in Chennai | 0% down payment</title>
        <meta name="description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!"></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
        <meta name="author" content="ProGear Automotive Pvt Ltd" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="ProGear car subscription in Chennai | 0% down payment" />
        <meta property="og:description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!" />
        <meta property="og:image" content="../../../public/favicon.ico" />
        <meta property="og:url" content="https://www.progearautomotive.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.progearautomotive.com/"></link>
      </Helmet>
      <Banner
        imgSrc={HomeBanner}
        imgAlt='Banner'
        imgMdSrc={HomeBanner1}
        imgMdAlt='Banner_md'
        text1='Own a car Now !'
        text2='We make it easy for you'
        text3='Explore our all-inclusive subscription for new and pre-owned cars.'
      />
      <OutsetBanner
        bannerSrc={outsetBanner}
        bannerAlt='Banner'
        leftContentData={leftContentData}
        rightContentData={rightContentData}
        text1='What is'
        text2='ProGear all inclusive car subscription?'
        text3="Introducing ProGear, India's premier monthly car subscription service, providing certified new and pre-owned cars for personal and business use. Enjoy flexibility and affordability like never before."
      />
      <Howitworks />
      <Savemoney
        text1='Enjoy your risk free drive with'
        text2='ProGear Subscription'
        text3='Subscribing a car is a convenient way than buying a car on loan'
      />
      <Subscription />
      <Partners />
      <HomeFAQ faqsTitle="Most Frequently Asked Questions" accordionData={accordionData}/>
    </>

  )
})

export default Home