const handleEmailClick = () => {
  window.location.href = 'mailto:support@progearautomotive.com';
};

const handleContactClick = () => {
  window.location.href = 'tel:+919094008000';
};

export const TeamsandConditionDetails = [
  <span>These <b>Terms of Use</b> govern the use of the <a href='https://www.progearautomotive.com' rel="noreferrer" target="_blank">www.progearautomotive.com</a> website ("Website"). The Website is owned and operated by ProGear Automotive Private Limited, a Company incorporated under the Companies Act, 2013 with its registered office at<b> No. 236, Lakshmi Avenue, IAF Road, Selaiyur, Chennai - 600073, IN</b> and corporate office address at <b> Plot.no: 66A, BHEL Nagar, 1st main road, Medavakkam, Chennai - 600 100, IN.</b> (hereinafter referred to as <b>“Progear Automotive”</b> or <b>"We"</b> or <b>"Us"</b> or <b>"Our"</b>).</span>,
  <span>This document/agreement/understanding is a computer-generated electronic record published in terms of Rule 3 of the Information Technology (Intermediaries Guidelines) Rules, 2011 read with Information Technology Act, 2000 and does not require any physical or digital signatures.</span>,
  <span>For the purpose of these Terms of Use, wherever the context so requires, (<b>"You"</b> or <b>"Your"</b> or <b>"Yourself"</b> or <b>"Buyer"</b> ) shall mean a natural or legal person interested in opting motor vehicles on lease /subscription that are listed on the Website and availing such other services made provided by Progear Automotive Pvt Ltd (collectively referred to as the<b> "Platform"</b>). This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the platform.</span>,
  <span>These Terms of Use shall be read in conjunction with the Master Lease Agreement signed and executed between the Company and User.</span>,
  <span><b>THESE TERMS OF USE ARE SUBJECT TO REVISION BY US AT ANY TIME PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING OR REGISTERING, BROWSING OR ACCESSING OR OTHERWISE USING THE PLATFORM. THE REVISED TERMS OF USE SHALL BE MADE AVAILABLE ON THE PLATFORM. YOU ARE REQUESTED TO VIEW THE MOST CURRENT TERMS OF USE. IT SHALL BE YOUR RESPONSIBILITY TO CHECK THESE TERMS OF USE PERIODICALLY FOR CHANGES. WE MAY REQUIRE YOU TO PROVIDE YOUR DIRECT OR INDIRECT CONSENT TO ANY UPDATE IN A SPECIFIED MANNER BEFORE FURTHER USE OF THE PLATFORM. IF NO SUCH SEPARATE CONSENT IS SOUGHT YOUR CONTINUED USE OF THE PLATFORM SHALL SIGNIFY YOUR ACCEPTANCE OF THE TERMS OF USE AND YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.<br /><br/> <br/> REFERENCE TO MOTOR VEHICLES SHALL MEAN THE REFERENCE TO CARS/AUTOMOBILE ONLY UNLESS THE PLATFORM OFFERS SERVICES EXTENDED TO OTHER FORMS OF AUTOMOBILES IN WHICH CASE THE TERM AUTOMOBILES SHALL INCLUDE SUCH OTHER FORMS.</b></span>,
  <span style={{display:'flex',justifyContent:'center'}}><b><u>PART A- GENERAL TERMS RELATING TO PLATFORM</u></b></span>,
  <span>
    <ul>I. Eligibility to Use:
      <li>Use of the Platform is not available to minors under the age of eighteen (18) years and excludes persons who are incompetent to contract within the meaning of the Indian Contract Act, 1872, including minors, undischarged insolvents or to any Users suspended or removed from the Platform by Progear Automotive Pvt Ltd for any reason whatsoever. If You are disqualified as per the preceding sentence, you shall not be permitted to avail of the Services or use the Website. You represent that You are of legal age to form a binding contract and are not a person barred from receiving the Services under the laws as applicable in India.</li>
      <li>Progear Automotive Pvt Ltd reserves the right to refuse access to use the Services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.</li>
      {/* <li>You shall not have more than one active Account (defined hereunder) on the Platform. Additionally, You are prohibited from selling, trading, or otherwise transferring Your Account to another person.</li> */}
    </ul>
    {/* <ul>II. Registration of Your Account
      <li>You may access and use the Platform either as a registered User or as a guest User. However, not all sections of the www.progearautomotive.com will be accessible to all guest users.</li>
      <li>You can create a registered user account by providing ProGear Automotive certain User information as requested by Us following which You can create a specific log-in ID and password (“Account”)</li>
      <li>If You use the Platform, You are responsible for maintaining the confidentiality of Your account and password and for restricting access to Your computer to prevent unauthorised access to Your account. You agree to accept responsibility for all activities that occur under Your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if You have any reason to believe that Your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner.</li>
      <li>Please ensure that the details you provide Us with are correct and complete and inform Us immediately of any changes to the information that you provided when registering. You can access and update much of the information you provided Us with in the Your Account area of the Platform. You agree and acknowledge that you will use your account on the Platform to make purchases only for your personal use and not for business purposes. Should you wish to order products for business purposes, please reach out support@progearautomotive.com. Progear Automotive reserves the right to refuse access to the Platform, terminate accounts, remove or edit content at any time without notice to you.</li>
      <li>You may be required to provide certain personal information and We may collect certain personal information. Your provision of, and Progear Automotive’s collection, storage, use, disclosure and otherwise dealing of such personal information shall be governed by Progear Automotive Pvt Ltd’s privacy policy, which is available at <a href='https://www.progearautomotive.com/terms-and-conditions/privacy-policy/'>https://www.progearautomotive.com/terms-and-conditions/privacy-policy/</a> ("Privacy Policy").</li>
    </ul> */}
    <ul>II. User Representations and Obligations:
      <li>Subject to the compliance with the Terms of Use, Progear Automotive grants you a non-exclusive, limited privilege to access and use this Platform and the Platform Services.</li>
      <li>You agree to use the Platform Services, Platform and the materials provided therein only:
        <ul>
        <li style={{ listStyleType: "none" }}>(a) for purposes that are permitted by the Terms of Use; and</li>
        <li style={{ listStyleType: "none" }}>(b) in accordance with any applicable law, regulation or generally accepted practices or guidelines.</li>
        </ul>
      </li>
      <li>You shall solely be responsible for Your employees, subcontractors and representatives and all claims made in relation to or by Your employees, subcontractors and representatives, and Progear Automotive shall not be responsible for them in any manner whatsoever.</li>
      <li>You agree to not engage in activities that may adversely affect the use of the Platform by other Customers/ Progear Automotive Pvt Ltd/registered trading partners.</li>
      <li>You agree not to access (or attempt to access) the Platform and the materials or Platform Services by any means other than through the interface that is provided by Progear Automotive Pvt Ltd. You shall not use any deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar.</li>
      <li>You acknowledge and agree that by accessing or using the Platform or Platform Services, you may be exposed to content from others (including but not limited to, Other Customers, registered trading partners, and other users/visitors) that You may consider offensive, indecent or otherwise objectionable. Progear Automotive disclaims all liabilities arising in relation to such offensive content on the Platform.</li>
    </ul>
    <ul>III. Intellectual Property<br />
      <span>Unless otherwise indicated, all rights to this Website and the Information, including copyrights and other intellectual property rights, are owned by Progear Automotive or its Licensors. Users are permitted to read the Website and the Information and make copies for their own personal use, for example by printing or storing in it. All other use of the Website or the Information, for example the storage or reproduction of (a part of) the website of Progear Automotive in any external internet site, is prohibited without the express permission of Progear Automotive.<br /><b>“Trademarks”</b> are registered trademarks of Progear Automotive.</span>
    </ul>
    <ul>IV. Unsolicited Ideas:
      <span>You hereby indemnify and hold Progear Automotive harmless from and against all actions, claims and liabilities, suffered, incurred or sustained by Progear Automotive as a result of the use and/or exploitation of the Materials infringing the (intellectual property) rights of any third party or otherwise being unlawful towards a third party.</span>
    </ul>
    <ul>V. Disclaimer of Warranties & Liability:<br />
      <span>You expressly understand and agree that, to the maximum extent permitted by applicable law: the platform, platform services, other services and other materials are provided by progear automotive on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose. without limiting the foregoing, progear automotive makes no warranty that,</span>
      <li>The platform or the platform services or other services will meet your requirements or your use of the platform or the platform services will be uninterrupted, timely, secure or error-free;</li>
      <li>The results that may be obtained from the use of the platform or platform services will be effective, accurate or reliable;</li>
      <li>The quality of the platform or platform services will meet your expectations; or</li>
      <li>Any errors or defects in the platform or platform services will be corrected. noadvice or information, whether oral or written, obtained by you from progear automotiveor through use of the platform services shall create any warranty not expressly stated in the terms of use.</li>
      <span>ProGear Automotive will have no liability related to any registered trading partner/ buyer content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. ProGear Automotive also disclaims all liability with respect to the misuse, loss, modification or unavailability of any trading partner/customer content.<br />
        progear automotive will not be liable for any loss that you may incur as a consequence of unauthorized use of your account or account information in connection with the platform or any platform services, either with or without your knowledge.<br />
        ProGear Automotive has endeavored to ensure that all the information on the platform is correct, but it neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, or platform service. progear automotive shall not be responsible for the delay or inability to use the platform or related functionalities, the provision of or failure to provide functionalities, or for any information, software, functionalities and related graphics obtained through the platform, or otherwise arising out of the use of the platform, whether based on contract, tort, negligence, strict liability or otherwise. further, ProGear Automotive shall not be held responsible for non-availability of the platform during periodic maintenance operations or any unplanned suspension of access to the platform that may occur due to technical reasons or for any reason beyond our control.
      </span>
    </ul>
    <ul>VI. Indemnification and Limitation of Liability:<br />
      <span>You agree to indemnify, defend and hold harmless Progear Automotive and its affiliates including but not limited to its (and its affiliates’) officers, directors, consultants, agents and employees (<b>“Indemnitees”</b>) from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by the Indemnitees that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms of Use. Further, You agree to hold the Indemnitees harmless against any claims made by any third party due to, or arising out of, or in connection with, Your use of the Platform or Platform Services, Your violation of the Terms of Use, or Your violation of any rights of another, including any intellectual property rights.<br />In no event shall the Progear Automotive, its officers, directors, consultants, agents and employees, be liable to You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not the Progear Automotive has been advised of the possibility of such damages, arising out of or in connection with</span>
      <li>Your use of or access to the Platform, Platform Services or materials on the Platform; or</li>
      <li>seller support services provided by registered trading partners.</li>
      <span>The limitations and exclusions in this Section apply to the maximum extent permitted by applicable laws.</span>
    </ul>
    <ul>VII. Violation of the Terms of Use<br />
      <span>You agree that Progear Automotive may, in its sole discretion and without prior notice, terminate Your access to the Platform for cause, delist You and block Your future access to the Platform if Progear Automotive determines that You have violated these Terms of Use. You also agree that any violation by You of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to Progear Automotive, for which monetary damages would be inadequate, and You consent to Progear Automotive obtaining any injunctive or equitable relief that Progear Automotive deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies that Progear Automotive may have at law or in equity.<br /> If Progear Automotive does take any legal action against You as a result of your violation of these Terms of Use, Progear Automotive will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other relief granted to Progear Automotive.</span>
    </ul>
    <ul>VIII. Governing Law<br />
      <span>These Terms of Use and all transactions entered into on or through the Platform and the relationship between You and Progear Automotive shall be governed in accordance with the laws of India without reference to conflict of laws principles.<br /> You agree that all claims, differences and disputes arising under or in connection with or in relation to the Platform, the Terms of Use or any transactions entered into on or through the Platform or the relationship between You and Progear Automotive shall be subject to the exclusive jurisdiction of the Courts at Delhi, India and You hereby accede to and accept the jurisdiction of such Courts.</span>
    </ul>
    <ul>IX. Report Abuse<br />
      <span>In the event You come across any abuse or violation of these Terms of Use or if You become aware of any objectionable content on the Platform, please report the same to the following e-mail id:<span className='contact' onClick={handleEmailClick}>support@progearautomotive.com</span> or contact at <span className='contact' onClick={handleContactClick}>+91 9094008000.</span></span>
    </ul>
    <ul>X. Communications<br />
      <span>You hereby expressly agree to receive communications by way of SMS and/or e-mails from Progear Automotive relating to the Platform Services provided through the Platform.<br />You can unsubscribe/ opt-out from receiving communications from Progear Automotive through SMS and e-mail anytime at <span className='contact' onClick={handleEmailClick}>support@progearautomotive.com</span></span>
    </ul>
    <ul>XI. General Provisions<br />
      <span><b>Notice :</b> All notices from Progear Automotive will be served by email to Your registered email address or by general notification on the Platform. Any notice provided to Progear Automotive pursuant to the Terms of Use should be sent to <span className='contact' onClick={handleEmailClick}>support@progearautomotive.com</span> with subject line - Attention: TERMS OF USE.<br />
        <br/><b>Assignment:</b> You cannot assign or otherwise transfer the Terms of Use, or any rights granted hereunder to any third party. Progear Automotive’s rights under the Terms of Use are freely transferable by Progear Automotive to any third party without the requirement of seeking Your consent.<br />
        <br/><b>Severability:</b> If, for any reason, a court of competent jurisdiction finds any provision of the Terms of Use, or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect.<br />
        <br/><b>Waiver:</b> Any failure by Progear Automotive to enforce or exercise any provision of the Terms of Use, or any related right, shall not constitute a waiver by Progear Automotive of that provision or right.
      </span>
    </ul>
  </span>
];
