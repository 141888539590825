import React, { memo } from 'react';
import ContactUsBanner from '../../Assests/ContactUsBanner.png';
import './ContactUs.scss';
import { FiPhoneCall } from "react-icons/fi";
import { LuMail } from "react-icons/lu";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Form from '../../Components/Form/Form.jsx'
import { Helmet } from 'react-helmet';

const ContactUs = memo(() => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | ProGear car subscription in Chennai | 0% down payment</title>
        <meta name="description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!"></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
        <meta name="author" content="ProGear Automotive Pvt Ltd" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="Contact Us | ProGear car subscription in Chennai | 0% down payment" />
        <meta property="og:description" content="ProGear is the best car subscription service provider in chennai. Avail the benefit of car subscription in an easier way and hassle free. Book your car today!" />
        <meta property="og:image" content="../../../public/favicon.ico" />
        <meta property="og:url" content="https://www.progearautomotive.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.progearautomotive.com/contact-us/"></link>
      </Helmet>
    <section>
      <img src={ContactUsBanner} className='ContactBanner' alt='Contact Us - Banner' />
      <div className='Contactus'>
        <span className='title'>Contact Us</span>
        <div className='ContactusInfo'>
          <div className='Contactdetails'>
            <div className='ContactDetailsLeft'>
              <div className='ContactDetailsLeftContact'>
                <span><FiPhoneCall /></span>
                <span>+91 90940 08000</span>
              </div>
              <div className='ContactDetailsLeftContact'>
                <span><LuMail /></span>
                <span>support@progearautomotive.com</span>
              </div>
            </div>
            <div className='ContactDetailsRight'>
              <span><HiOutlineLocationMarker /></span>
              <div className='CompanyNameDetails'>
                <span className='CompanyName'>Progear Automotive Pvt Ltd</span>
                <span className='CompanyAddress'>Plot.no: 66A, BHEL Nagar, 1st main road, Medavakkam, Chennai - 600 100</span>
              </div>
            </div>
          </div>
          <Form />
        </div>
      </div>
    </section >
    </>
  )
});

export default ContactUs;
