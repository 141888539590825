import React, { memo } from 'react';
import './Banner.scss';

const Banner = memo(({ imgSrc, imgAlt, imgMdSrc, imgMdAlt, text1, text2, text3, isContactPage }) => {
  return (
    <>
      <section>
        <div className='Banner'>
          <img src={imgSrc} className='banner' alt={imgAlt} />
          <img src={imgMdSrc} className='banner_md' alt={imgMdAlt} />
          <div className='bannertextlayout'>
            <h1 className={`bannertext1 ${isContactPage ? 'white-text' : ''}`}>{text1}</h1>
            <h1 className={`bannertext2 ${isContactPage ? 'white-text' : ''}`}>{text2}</h1>
            <span className={`bannertext3 ${isContactPage ? 'white-text' : ''}`}>{text3}</span>
            {/* <button type='submit' className='browsebtn'>Browse Cars</button> */}
          </div>
        </div>
      </section>
    </>
  );
});

export default Banner;
