import React, { memo } from 'react'
import FAQ from '../../Components/Accordian/FAQ'
import './CorporateFAQ.scss';
import { menuItems, accordionData } from '../../Components/Accordian/CorporateFAQData';
import { Helmet } from 'react-helmet';

const CorporateFAQ = memo(() => {
  return (
    <>
    <Helmet>
          <meta charSet="utf-8" />
          <title>Corporate FAQ | Progear - Trusted corporate car leasing service in Chennai</title>
          <meta name="description" content="Compare, choose & lease. ProGear offers easier and more affordable car leasing services for businesses. Lease today! Free from Fleet Management Tomorrow"></meta>

          {/* SEO meta tags */}
          <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
          <meta name="author" content="ProGear Automotive Pvt Ltd" />

          {/* Mobile-specific meta tags */}
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="theme-color" content="#ffffff" />

          {/* Open Graph meta tags for social sharing */}
          <meta property="og:title" content="Progear - Trusted corporate car leasing service in Chennai"/>
          <meta property="og:description" content="Compare, choose & lease. ProGear offers easier and more affordable car leasing services for businesses. Lease today! Free from Fleet Management Tomorrow" />
          <meta property="og:image" content="../../../public/favicon.ico" />
          <meta property="og:url" content="https://www.progearautomotive.com/" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://www.progearautomotive.com/corporate-faq/"></link>
        </Helmet>
    <section>
      <div className='corporateFAQ'>
        <FAQ faqsTitle="Corporate FAQs" menuItems={menuItems} accordionData={accordionData} />
      </div>
    </section>
    </>
  )
})

export default CorporateFAQ
