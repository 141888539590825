import React from 'react';
import './Partners.scss';
import BusinessMan from '../../Assests/BusinessMan.png';
import Feedback from '../FeedBack/Feedback';
// import BTN from '../BTN/BTN';
const Partners = () => {
  const handleClick = () => {
    window.open('/corporate-lease', '_blank');
  };
  return (
    <section>
      <div className='partner'>
        <div className='Partnerandcustomer'>
          <div className='partnertitlelay'>
            {/* <div className='partnertitlecontent'>
              <span className='partnertitletext'>100+ Trusted</span>
              <span className='partnertitle'>Corporate Partners</span>
            </div>
            <div className='partnericon'>
              <PartnerIcon src={Cleartax} alt='Cleartax' />
              <PartnerIcon src={Moneytap} alt='Moneytap' />
              <PartnerIcon src={Hike} alt='Hike' />
              <PartnerIcon src={Pagarbook} alt='Pagarbook' />
              <PartnerIcon src={Indiagold} alt='Indiagold' />
            </div>  */}
            <div className='BusinessFrame'>
              <div className='BusinessFramelayout'>
                <div><img src={BusinessMan} alt='ProGear For Business?' /></div>
                <div className='Businesscontent'>
                  <span className='BusinessTitle'>ProGear For Business?</span>
                  <span className='BusinesscontentText'>Enable more tax benefits and even more savings through salary sacrifice or company cars.</span>
                  <span className='BusinesscontentText'>Avail these exclusive benefits now.</span>
                  {/* <BTN /> */}
                  <button type='submit' className='browsebtn' onClick={handleClick}>Know More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Feedback />
      </div>
    </section>
  );
};

export default Partners;
