import React, { memo } from 'react'
import './CorporateLease.scss';
import Banner from '../../Components/Banner/Banner';
import ProGearLease from '../../Components/ProGearLease/ProGearLease';
import CorporateFAQ from '../../Components/Accordian/FAQ';
import { menuItems, accordionData } from '../../Components/Accordian/CorporateFAQData';
// import HomeBanner from '../../Assests/CPLHomeBanner.svg';
import HomeBanner from '../../Assests/CPLHomeBanner.png';
import HomeBanner1 from '../../Assests/CPLHomeBanner_md.svg'; 
// import HomeBanner1 from '../../Assests/CPLHomeBanner_md.png';
import LeaseModel from '../../Components/LeaseModel/LeaseModel';
import Feedback from '../../Components/FeedBack/Feedback';
// import CorporatePartner from '../../Components/CorporatePartner/CorporatePartner';
import BecomePartner from '../../Components/BecomePartner/BecomePartner';
import Benifites from '../../Components/Benifites/Benifites';
import { Helmet } from 'react-helmet';

const CorporateLease = memo(() => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Corporate Lease | Progear - Trusted corporate car leasing service in Chennai</title>
        <meta name="description" content="Compare, choose & lease. ProGear offers easier and more affordable car leasing services for businesses. Lease today! Free from Fleet Management Tomorrow"></meta>

        {/* SEO meta tags */}
        <meta name="keywords" content="car lease, car lease in chennai, car subscription in chennai, car subscription service, car subscription chennai, car rental service, car rental, car leasing, car rental chennai, car rental in chennai, monthly car subscription, monthly car rental, used car subscription, premium car subscription, premium car lease, used car lease" />
        <meta name="author" content="ProGear Automotive Pvt Ltd" />

        {/* Mobile-specific meta tags */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph meta tags for social sharing */}
        <meta property="og:title" content="Corporate Lease | Progear - Trusted corporate car leasing service in Chennai" />
        <meta property="og:description" content="Compare, choose & lease. ProGear offers easier and more affordable car leasing services for businesses. Lease today! Free from Fleet Management Tomorrow" />
        <meta property="og:image" content="../../../public/favicon.ico" />
        <meta property="og:url" content="https://www.progearautomotive.com/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.progearautomotive.com/corporate-lease/"></link>
      </Helmet>
      <section>
        <Banner imgSrc={HomeBanner} imgAlt='Banner' imgMdSrc={HomeBanner1} imgMdAlt='Banner_md' text1='Make a Flexible and Smart Move' text2='Corporate Car Lease' text3='Fuel your employee car aspirations with tax saving and seamless experience through corporate lease.' />
        <Benifites />
        <ProGearLease text1='Save your money with car subscription' text2='ProGear Lease vs Loan' text3='Subscribing to a car is not just flexible and convenient, it might also save your money as compared to buying a car on loan' />
        <LeaseModel />
        <div className='CPTPartner'>
          {/* <CorporatePartner /> */}
          <Feedback />
        </div>
        <BecomePartner />
        <CorporateFAQ faqsTitle="Corporate FAQs" menuItems={menuItems} accordionData={accordionData} />
      </section>
    </>
  )
})

export default CorporateLease