export const content = [
    {
        type: 'MISSION',
        title: '“Facilitating mobility one lease at a time, via leasing solutions”',
        description: 'At ProGear our mission is to provide flawless and flexible car leasing solutions to embolden individuals, businesses and corporate clients to achieve their mobility goals'
    },
    {
        type: 'VISION',
        title: '“Fuelling advancement, envisioning the future”',
        description: 'At ProGear our vision is to provide flawless and flexible car leasing solutions to embolden individuals, businesses and corporate clients to achieve their mobility goals'
    },
    {
        type: 'VALUE',
        title: '“Leasing journeys, along with cars”',
        description: "At ProGear, we're not just leasing cars; we're enabling journeys, fostering growth and shaping the future of mobility."
    }
];
