import React, { memo } from 'react';
import './OutsetBanner.scss';

const OutsetBanner = memo(({ bannerSrc, bannerAlt, leftContentData, rightContentData, text1, text2, text3, leftHeading, rightHeading }) => {
  return (
    <>
      <section>
        <div className='Outset'>
          <img src={bannerSrc} className='banner' alt={bannerAlt} />
          <div className='Outsetbannerlayout'>
            <span className='Outsettext1'>{text1}</span>
            <span className='Outsettext2'>{text2}</span>
            <span className='Outsettext3'>{text3}</span>
            <div className='tablecontent'>
              <div className='leftcontent'>
                <span className='contentHeading'>{leftHeading}</span>
                {leftContentData.map((item, index) => (
                  <div className='textspan' key={index}>
                    <div><img src={item.icon} alt={`icon- ${item.text}`} className='iconimg' /></div>
                    <div>{item.text}</div>
                  </div>
                ))}
              </div>
              <div className='rightcontent'>
                <span className='contentHeading'>{rightHeading}</span>
                {rightContentData.map((item, index) => (
                  <div className='textspan' key={index}>
                    <div><img src={item.icon} alt={`icon- ${item.text}`} className='iconimg' /></div>
                    <div>{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
});

export default OutsetBanner;
