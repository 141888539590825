import React, { memo } from 'react'
import Star from './Star';
import './Feedback.scss'
const Feedback = memo(() => {
    return (
        <div className='customersays'>
            <span className='customersaysTitle'>Read what our customer says</span>
            <div className='Feedback'>
                <div className='card'><span className='customername'>Venkat</span><span><Star /></span><span>Excellent service from progear. They assist me with every aspect and make the process run smoothly. Door step car delivery provided as mentioned within 4-5 days from the date of booking.</span></div>
                <div className='card'><span className='customername'>Sarathy</span><span><Star /></span><span>I have taken the car for Lease two times from progear without any mirror malfunctioning. Their response and service was very good. Delivered car is the right time after every service support.</span></div>
                <div className='card'><span className='customername'>Uma</span><span><Star /></span><span>Progear provided very good support throughout the process without any  delay in the verification process. Delivered car within the mentioned date and provided good service in time. Mr. Nikhil was helpful on every issue.</span></div>
            </div>
        </div>
    )
})

export default Feedback