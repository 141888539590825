import React, { memo } from 'react'
import './USP.scss';

const USP = memo(() => {
  return (
    <section className='container'>
        <div className='content'>
            <span className='title'>Our UNIQUE SELLING PROPOSITION </span>
            <span className='definition'>ProGear focuses on reducing the burden of its customers, we take care of your requirements and also your cars maintenance</span>
            <span className='note'>Subscription to ProGear = Stress free Driving</span>
            <span className='description'>Our car leasing and subscription service relieves you from the hassles of car ownership, maintenance, and long-term commitments. Enjoy flexibility, hassle-free driving, and peace of mind with our customizable plans.</span>
        </div>
    </section>
  )
})

export default USP