import React, { useState, memo } from 'react';
import './FAQ.scss';

const FAQ = memo(({ faqsTitle, menuItems, accordionData }) => {
  const Accordion = ({ title, content, isActive, onClick }) => (
    <div className="accordion-item">
      <div className="accordion-title" onClick={onClick}>
        <div>{title}</div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );

  const [activeMenuItem, setActiveMenuItem] = useState(menuItems[0]);
  const [filteredAccordionData, setFilteredAccordionData] = useState(accordionData.filter(item => item.category === menuItems[0]));
  const [activeIndex, setActiveIndex] = useState();

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleMenuItemClick = (menuItem) => {
    const filteredItems = accordionData.filter(item => item.category === menuItem);
    setFilteredAccordionData(filteredItems);
    setActiveMenuItem(menuItem);
    setActiveIndex(null);
  };

  return (
    <section>
      <div className="FAQ">
        <div className='FQAContent'>
          <span className="FAQTitle">{faqsTitle}</span>
          <div className='FAQDetails'>
            <div className='FAQDetailscontent'>
              <div className='menu-container'>
                <div className='menulist'>
                  {menuItems.map((item, index) => (
                    <span
                      key={index}
                      className={activeMenuItem === item ? 'active' : ''}
                      onClick={() => handleMenuItemClick(item)}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </div>
              <div className="accordion-container">
                <div className="accordion">
                  {filteredAccordionData.map((item, index) => (
                    <Accordion
                      key={index}
                      title={item.title}
                      content={item.content}
                      isActive={index === activeIndex}
                      onClick={() => handleAccordionClick(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default FAQ;
