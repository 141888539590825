export const menuItems = [
  "Subscription",
  "Booking",
  "Payment",
  "Maintenance",
  "Insurance",
  "Breakdown",
  "Legal",
  "Extension",
  "Foreclosure",
  "Guidelines"
];
export const accordionData = [
    {
      category: 'Subscription',
      title: 'What is ProGear lease/subscription model?',
      content: `It's a new method of owning new and pre-owned cars of your choice, without having to worry about a down payment, bank debt, vehicle registration, insurance, upkeep, or servicing for a specific period. These are all packaged together and offered to you as a complete package.`,
    },
    {
      category: 'Subscription',
      title: 'What are the benefits of ProGear car subscription program?',
      content: <ul><li>No depreciation risks</li>
      <li>No maintenance and service issue</li>
      <li>No insurance and tax pay</li>
      <li>Flexible tenures</li>
      <li>Stay up with the latest cars</li></ul>,
    },
    {
      category: 'Subscription',
      title: 'For how long can I subscribe to a car?',
      content: `Minimum period of car subscription starts from 6 months to maximum 36 months.`,
    },
    {
      category: 'Subscription',
      title: 'How ProGear subscription is different from car rental or self drive car?',
      content: `Unlike self-drive services where a car with a yellow or black license plate, ProGear gives you access to a refurbished car with a white number plate. Here, you may easily drive the vehicle for the chosen period of time and, at the end, return or upgrade the vehicle, or extend  the term. `,
    },
    {
      category: 'Subscription',
      title: 'What are the services provided by ProGear subscription services?',
      content: <ul><li>Insurance - Comprehensive with Zero Depreciation</li>
      <li>Insurance claim Management</li>
      <li>All kind of service and maintenance including consumables</li>
      <li>Free pickup and drop</li>
      <li>24x7 Roadside Assistance</li></ul>,
    },
    {
      category: 'Booking',
      title: 'What is the eligibility criteria for ProGear subscription?',
      content: `Eligibility criteria for ProGear subscription is based on the credit score, KYC verification and income verification(6-month bank statements, Credit check, Salary slip etc).`,
    },
    {
      category: 'Booking',
      title: 'Why does ProGear checks for the credit score?',
      content: `Credit score helps in the approval of application for car subscription.`,
    },
    {
      category: 'Booking',
      title: 'Will credit check will impact my credit score?',
      content: `No, it won’t impact your credit score at any cost as we do a soft credit score check. While checking if you miss out your monthly payment, it will have a negative impact on your credit score.`,
    },
    {
      category: 'Booking',
      title: 'What documents will be required for verification?',
      content:
      <ul>
        List of documents for Salaried Individual :
        <li>Current address proof</li>
        <li>Permanent address proof</li>
        <li>Aadhar Number</li>
        <li>Last 6-month bank statements</li>
        <li>PAN card</li>
        <li>Company/working details (for salaried individuals)</li>
        <br></br>
        List of documents for Self Employed Individuals:
        <li>Current address proof</li>
        <li>Permanent address proof</li>
        <li>Aadhar Number</li>
        <li>Last 6-month bank statements</li>
        <li>Other documents (ITR, Company PAN etc.)</li>
        <li>If necessary, some other documents might be required to process the Lease/subscription</li>
      </ul>
    },    
    {
      category: 'Booking',
      title: 'How much time will be required for detailed eligibility check?',
      content: `If mentioned documents are available in prior, then soft credit check will take upto 30 mins but in some cases it take upto 1 - 2 days.`,
    },
    {
      category: 'Booking',
      title: 'When will I get my car?',
      content: `After completing your security deposit and token amount. Your car will be booked.`,
    },
    {
      category: 'Payment',
      title: 'What is the token amount ?',
      content: `Token amount is the initial amount paid for confirmation of subscription. The token amount wil be Rs. 4999. This fee is refundable if you want to cancel  the subscription.`,
    },
    {
      category: 'Payment',
      title: 'Is there any security deposit?',
      content: `Yes, you must pay a refundable security deposit of 3 months of subscription fees (amount may vary based on credit profile). Additionally, based on your credit history and subscription duration, you might additionally need to submit 1/2 or 3 PDCs. At the end of tenure, you will be refunded, less any excess kilometers charged and any damages other than typical wear and tear.`,
    },
    {
      category: 'Payment',
      title: 'Is there any booking amount?',
      content: `Yes, you have to pay one month of security amount before placing the order. This fee will adjust with security deposit at the time of delivery.`,
    },
    {
      category: 'Payment',
      title: 'What do I need to pay to get started?',
      content: `You will be asked to pay two month of security deposit and one month of subscription amount in advance.`,
    },
    {
      category: 'Payment',
      title: 'Does my subscription amount include all taxes?',
      content: `Yes, subscription amount is inclusive of all applicable taxes.`,
    },
    {
      category: 'Payment',
      title: 'What are the payment options available?',
      content: `We accept credit card, debit card, net banking, and UPI payments for tokens, security deposits, and advance rentals.`,
      // You must enable e-NACH in order to have your monthly subscription payment automatically debited from your bank account.
    },
    {
      category: 'Payment',
      title: 'When is my first monthly payment due?',
      content: `Starting subscription date will be the due date for monthly subscription payment.`,
      // Every month, the monthly subscription cost will be automatically deducted from the bank account you have linked to our e-NACH feature. Ensure that your bank account is adequately filled to enable automatic payments to be made each month.
    },
    {
      category: 'Payment',
      title: 'Is there is any extra fee for delayed payment?',
      content: `Yes, there is delayed payment charges if you missed to pay within the due date.`,
    },
    {
      category: 'Payment',
      title: 'When will  I get my refund ?',
      content: `At the end of the tenure your security deposit will be refunded. Detection will be done based on the car condition.`,
    },
    {
      category: 'Maintenance',
      title: 'How do I reach out incase of any service issues?',
      content: `You can reach out to our service team through email or given phone number.`,
    },
    {
      category: 'Maintenance',
      title: 'Is pick up and drop provided incase of any service/repair work?',
      content: `Yes, that’s all covered under subscription.`,
    },
    {
      category: 'Maintenance',
      title: 'What is covered under end to end warranty?',
      content: `End to end warranty will cover, the entire repair and maintenance. These are the aspects that will be under subscription.`,
    },
    {
      category: 'Maintenance',
      title: 'What will not be covered under end to end warranty?',
      content: `User negligence & mishandling cases or claims that the insurance company rejects will not be covered under end to end warranty.`,
    },
    {
      category: 'Maintenance',
      title: 'What is covered under service and maintenance?',
      content: `In the service and maintenance package all scheduled and unscheduled services for the client-specific duration and the designated miles are included.`,
    },
    {
      category: 'Maintenance',
      title: 'What is not covered under service and maintenance?',
      content:<ul>
        <li>Fuel and additives.</li>
      <li>Washing & cleaning, Seat Dry cleaning/Upholstery cleaning and other value-added services which are not a part of the routine service schedule.</li>
      <li>Polishing, Anti-Rust treatment, Teflon coating.</li>
      <li>Flat tires and parking, Tolls.</li>
      <li>Repairs arising out of fitment of additional accessories to the electrical system of the car.</li>
      <li>Repairs resulting from abnormal use of the vehicle or from failure to follow the manufacturer’s handbook and service book – These includes :
      Burnt Clutches which arise out of continuous engagement between foot and the pedal.</li>
      <li>Broken / damaged parts in the interior of the car which has not resulted due to any external impacts.</li> <li>Common examples are – torn upholstery, broken AC vents due to improper usage, broken sun blinds.
      Damages / repairs arising out of negligence in operating accessories such as music system etc.</li>
      <li>If necessary, some other documents might be required to process the Lease/subscription.</li>
      <li>Faulty use (lack of oils- Engine / Gearbox/ Coolants, driving with a warning light on etc).</li>
      <li>Any 3rd party towing bills in case of breakdown and / or accident.</li></ul>,
    },
    {
      category: 'Maintenance',
      title: 'Will I get loaner or replacement car during service maintenance?',
      content: `No, we can’t provide a replacement car during service or maintenance.`,
    },
    {
      category: 'Insurance',
      title: 'Does my monthly rental lease also cover insurance?',
      content: `Yes, monthly subscription covers insurance
      .`,
    },
    {
      category: 'Insurance',
      title: 'Who will take care of coordination of the insurance company incase insurance claim?',
      content: `ProGear subscription includes an insurance claim management service. As such, the ProGear team will handle any insurance-related procedures, and as your single point of contact and will gather any documents or details that are needed from you.`,
    },
    {
      category: 'Breakdown',
      title: 'What do I do if vehicle breaks down?',
      content: `Please contact our given helpline number.`,
    },
    {
      category: 'Breakdown',
      title: 'What if I met with an accident?',
      content: `First take precautions for your safety. Kindly get in touch with us right away at service@progearautomotive.com or give us a call at +91 90940 08000 during business hours. In the unfortunate event that an accident occurs after business hours, please call the RSA Helpline number, which is open 24/7. In the event of theft or any human injury, kindly file a formal complaint (FIR) at the closest police station and provide a copy to ProGear. Within 24 hours following the incident, the FIR must be filed.`,
    },
    {
      category: 'Breakdown',
      title: 'What is the maximum amount to be paid in case the car met with an accident?',
      content: `There is a 5000 INR maximum liability cap per incidence. Additionally, you will be responsible for any disallowance made by the insurance company. A few instances of claim disallowance or repudiation include cases of drink and drive, driving licenses that are invalid, and any prior harm that has not been documented.`,
    },
    {
      category: 'Legal',
      title: 'What happens if the vehicle is involved in any traffic violation such as parking/moving, driving without seat beltsdriving in the wrong lane?',
      content: `You will be responsible for the damage costs, legal fees (if any) and other related costs if involved in any traffic violation.`,
    },
    {
      category: 'Legal',
      title: 'What if car/accessories/parts gets stolen?',
      content: `Kindly get in touch with us right away at service@progearautomotive.com or give us a call at +91 9094008000 during business hours. Please notify ProGear in writing and file a formal complaint at the closest police station. Within 24 hours following the incident, the FIR must be filed.`,
    },
    {
      category: 'Extension',
      title: 'Can I extend my tenure?',
      content: `Yes, you can extend your tenure. Since the extension is dependent on the car's availability, we advise you to take this action at least one month prior to the subscription's expiration date.`,
    },
    {
      category: 'Extension',
      title: 'How does car swap option works?',
      content: `You can swap your car, after your subscription period is over with same category or different category based on your credit score.`,
    },
    {
      category: 'Extension',
      title: 'How do I return the car after my subscription expires?',
      content: `Simply, you need to surrender the car to us or pick up service can also be used.`,
    },
    {
      category: 'Foreclosure',
      title: 'Can I close my subscription anytime I want?',
      content: `Yes, you have the option to cancel your subscription at any time. However, depending on how long you have had the automobile and your tenure, you may be charged a fee.`,
    },
    {
      category: 'Foreclosure',
      title: 'What is your lock-in period?',
      content: `50% of your tenure is the lock-in time. For instance, if you have chosen a 24-month tenure, your lock-in term will be 12 months.`,
    },
    {
      category: 'Foreclosure',
      title: ' What is the difference between the subscription period and lock-in period?',
      content: `The term of the subscription is the length of time you choose when you take the car on subscription, and the lock-in period is the minimum amount of time you have to keep the car. If you decide to cancel the subscription during this time, the foreclosure fees will differ before and after the lock-in period.`,
    },
    {
      category: 'Foreclosure',
      title: 'What will be the foreclosure charges if I end my subscription after the lock-in period?',
      content: <ul>
        <li>6/12 Months Tenure - 2 Month Rental as penalty and return the car in good order condition immediately</li>
        <li>24 Months tenure - 3 Months Rental as penalty and return the car in good order & condition immediately</li>
      Example : If you taken a car for 24 months tenure at 18000/months lease rental and you decide to foreclose in 14th month then you need to pay, 3 months rental i.e 18000*3= INR 54,000 as foreclosure charges.</ul>,
    },
    {
      category: 'Foreclosure',
      title: ' What will be the foreclosure charges if I end my subscription before the lock-in period?',
      content: <ul>
        <li>Total Lease Rentals due till the Lock-in Period + The Foreclosure Penalty - Total Rentals already paid till the foreclosure.</li>
      Example : If you taken a car for 24 months tenure at INR 18000/months lease rental and you decide to foreclose in 11th month then you need to pay the following amount: Total Lease Rentals due till the Lock-in Period(12*18000) + The Foreclosure Penalty(3*18000) - Total Rentals already paid till the foreclosure(11*18000) = INR 72,000</ul>     
    },
    {
      category: 'Guidelines',
      title: 'What type of registration will the car have?',
      content: `We exclusively offer cars equipped with white boards.`,
    },
    {
      category: 'Guidelines',
      title: 'Who will be the registered owner of the car?',
      content: `The registrated owner of the car would be in the name of the company or finance partner.`,
    },
    {
      category: 'Guidelines',
      title: 'What will be the condition of the car that I subscribed for?',
      content: `The car will be refurbished and feel almost brand new. We do a inspection check when doing the refurbishment, so it is very unlikely that there will be any problems. However, if you discover any problems after the car is delivered, ProGear will take care of them right away.`,
    },
    {
      category: 'Guidelines',
      title: 'Can I make any modifications to the car?',
      content: `Without consulting ProGear, you are not allowed to make any changes.`,
    },
    {
      category: 'Guidelines',
      title: 'What if I want to change my car after booking?',
      content: `You won't be able to change the automobile once you've booked it since our staff will acquire the vehicle in accordance with your request, so such requests won't be granted.`,
    },
    {
      category: 'Guidelines',
      title: 'Is there is any speed limit for the car that I subscribed for?',
      content: `No, there is no speed limitation.  We suggest you to have safer limit for your safety.`,
    },
    {
      category: 'Guidelines',
      title: 'Is there is any restriction on car use?',
      content: `You are free to use the vehicle as you would have your own, but renting a car, off-roading, using it in a race or using it for business purposes are not permitted.`,
    },
    {
      category: 'Guidelines',
      title: 'How the car condition is checked at the end of the tenure?',
      content: `At the end of the tenure, you are responsible for any additional damages other than normal wear and tear. You will charged based on the damage.`,
    },
    {
      category: 'Guidelines',
      title: 'Can I take the car to another city?',
      content: `Yes, you can drive the anywhere within India.`,
    },
]