import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import { FiPhone } from "react-icons/fi";
import Logo from '../../Assests/progear_new.svg';
import { IoPersonSharp } from "react-icons/io5";

const Navbar = () => {
  const handleClick = () => {
    window.location.href = 'tel:+919094008000';
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavItemClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setMenuOpen(false);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 660);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 660);
    };

    handleResize(); // Call handleResize once to set initial state

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to mimic componentDidMount behavior

  return (
    <nav className={`navbar ${menuOpen ? "open" : ""}`}>
      <div className="navbar-container">
        <input type="checkbox" checked={menuOpen} onChange={handleMenuToggle} id="menu-toggle" />
        <label htmlFor="menu-toggle" className="hamburger-lines">
          <span className="line line1"></span>
          <span className="line line2"></span>
          <span className="line line3"></span>
        </label>
        <div className="nav-brand">
          <Link to="/" onClick={handleNavItemClick}>
            <img src={Logo} className="logo" alt="ProGear Automotive" />
          </Link>
        </div>
        {isMobile ? (
          <div className="menu-toggle" onClick={handleMenuToggle}>
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
        ) : null}
        <div className={`menu-items ${menuOpen ? "open" : ""}`}>
          <Link to="/corporate-lease" className="nav-link" onClick={handleNavItemClick}>Corporate Lease</Link>
          {/* <Link to="#" className="nav-link" onClick={handleNavItemClick}>Self Driving</Link> */}
          <Link to="/faq" className="nav-link" onClick={handleNavItemClick}>FAQ</Link>
          <div className="nav-contact">
            <div className="svgs" onClick={handleClick}>
              <FiPhone />
            </div>
            <Link to="/contact-us" className="svgs" onClick={handleNavItemClick}> <IoPersonSharp /></Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
