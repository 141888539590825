const handleEmailClick = () => {
    window.location.href = 'mailto:support@progearautomotive.com';
  };
  
  const handleContactClick = () => {
    window.location.href = 'tel:+919094008000';
  };

export const privacyPolicyDetails = [
    <span>We at <a href='https://www.progearautomotive.com' rel="noreferrer" target="_blank">www.progearautomotive.com</a> recognise the importance of maintaining our end users’ privacy. We value your privacy and appreciate the trust you place in us. This Privacy Policy describes in detail how we treat user information we collect on our website at <b><a href='https://www.progearautomotive.com' rel="noreferrer" target="_blank">www.progearautomotive.com</a></b> and through other offline sources. This Privacy Policy applies to present and former website visitors and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.</span>,
    <span>Progear Automotive <a href='https://www.progearautomotive.com' rel="noreferrer" target="_blank">(www.progearautomotive.com)</a> is a property of ProGear Automotive Private Limited, an Indian Company registered under the Companies Act, 2013 having its registered office at<b> No. 236, Lakshmi Avenue, IAF Road, Selaiyur, Chennai - 600073, IN </b> and corporate office address at<b> Plot.no: 66A, BHEL Nagar, 1st main road, Medavakkam, Chennai - 600 100</b></span>,
    <span>This Privacy & Security Policy (here in after referred to a <b>“Policy”</b>) therefore, explains how we protect your personal information provided to us on our website i.e.<a href='https://www.progearautomotive.com' rel="noreferrer" target="_blank"> www.progearautomotive.com</a> (hereinafter referred to as <b>“Website”</b> ) and how we use that information in connection with the service offered through the Website (hereinafter called as <b>"Service"</b> ) and must be read in conjunction with our General Terms of Use.</span>,
    <span><i><b>This Policy is published and shall be construed to be in accordance with the provisions of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data of Information) Rules, 2011 under the Information Technology Act, 2000 and other applicable laws and regulations that require publishing of the privacy notice for collection, usage, storage, disclosure and transfer of sensitive personal data or information.</b></i></span>,
    <span>
              <ul>I. The information we collect.
                <li><b><i>Your contact information</i></b> We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and IP address, credit card or other payment information in certain cases.</li>
                <li><b><i>Information you post</i></b> We collect information you post on our website or on a third-party social media website belonging to Progear Automotive.</li>
                <li><b><i>Demographic information</i></b> We may collect demographic information about you, cars you shortlist, cars on which you request test drives, Progear Automotive car hubs/partner hubs/dealer hubs you visit, or any other information provided by you during the use of our website. We might collect this as a part of surveys also.</li>
                <li><b><i>Other information.</i></b> If you use our website, we may collect information about your device, IP address and the browser you're using. We might look at what website you came from, duration of time spent on our website, pages accessed or what website you visit when you leave us.</li>
              </ul>
              <ul>II. How we collect information.
                <li><b><i>We collect information directly from you.</i></b> We collect information directly from you when you sign up or request a test drive on the Site. We also collect information if you post a comment on our Site, request for a call back or ask us a question through phone or email.</li>
                <li><b><i>We collect information from you passively.</i></b> Non-personal information i.e. type of internet browser and operating system used, domain name of the website from which you came, number of visits, average time spent on the site/mobile application, pages viewed etc may be picked up automatically and without explicitly being provide by you, but during your interaction with the Website by use of certain technologies, such a Cookies and Web Beacons. Additionally, we collect your device’s connection to the internet, including your IP address, your device or computer’s name, and your operating system.</li>
                <li><b><i>We get information about you from third parties.</i></b> For example, if you use an integrated social media feature on our website. The third-party social media website may provide us certain information about you. This information could include your name and email address.</li>
              </ul>
              <ul>III. How your personal information is used.
                <li><b><i>We use information to contact you.</i></b> We might use the information you provide us to contact you for confirmation of a test drive request on our website or for other promotional purposes.</li>
                <li><b><i>We use information to respond to your requests or questions.</i></b> We might use your information to answer your queries concerning our products or services.</li>
                <li><b><i>We use information to improve our products and services.</i></b> We might use your information to customize your experience on our website. This may include displaying certain content based on your preferences and/or our website usage.</li>
                <li><b><i>We use information to look at site trends and customer interests.</i></b> We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.</li>
                <li><b><i>We use information for marketing purposes.</i></b> From time to time, we might send you information about special promotions or offers. We might also inform you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting.</li>
                <li><b><i>We use information to send you transactional communications.</i></b> We might send you emails or SMS about your account, any test drives you may request/undertake, any car purchase you may make or any car you may request to sell.</li>
                <li>We use information as otherwise permitted by law, or to comply with the law or orders of law enforcement agencies or to enforce our legal rights.</li>
                <li>We might use your information or logo for our website for marketing purpose or for showing our association with customers on our website.</li>
              </ul>
              <ul>IV. Your Consent.<br/>
                <span>We request you to carefully read this Privacy Policy and Terms of Use under the different tabs before sharing your personal information with us. By browsing, visiting, accessing and/or using the Services on the website (or searching for any of the pages on the Website), you explicitly consent and agree to our Privacy Policy laid out herein. You also agree that the information furnished by you is lawful, true and correct and does not violate or infringe any laws. No liability pertaining to the authenticity, genuineness, misrepresentation, fraud and/or negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be responsible to verify any information obtained from you.</span>
              </ul>
              <ul>V. Sharing of information with third-parties.
                <li><b><i>We will share information with third parties who perform services on our behalf.</i></b> We may share information with vendors who help us manage our online registration process or payment processors or transactional message processors.</li>
                <li><b><i>We may share information if we think we have to in order to comply with the law or to protect ourselves.</i></b> We will share information to respond to a court order or judicial summons. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud.</li>
                <li><b><i>We may share information with any successor to all or part of our business.</i></b> For example, if part of our business is sold we may give our customer list as part of that transaction.</li>
                <li><b><i>We may share your information for reasons not described in this policy.</i></b> We will tell you before we do this.</li>
              </ul>
              <ul>VI. Access to and Updating Your Information.<br/>
                <span>The accuracy of your information is important to us. We encourage you to periodically review the information you have provided to us and update the same if there are any changes or errors to the information.</span>
              </ul>
              <ul>VII. Use of Third-Party Service Providers.<br/>
                <span>We rely on third-party service providers to perform a variety of services on our behalf. To do so, we may need to share your information with them. For example, we may rely on service providers to fulfil service requests, and answer your questions, send e-mails on our behalf, and analyse data to improve our Services. We may also use third party service providers to collect, store and/or process your information. In each instance described above, such third-party service providers have assured us in writing that they apply data protection measures, at least equal to those set out below in the section “Data Security Measures”</span>
              </ul>
              <ul>VIII. Data Security Measures.<br/>
                <span>We have in place various procedures to safeguard your information, including technical, administrative and physical procedures intended to keep your information secure. In this regard, we follow the measures set out under the<b>International Standard IS/ISO/IEC 27001:2013 on “Information Technology-Security Techniques – Information Security Management System – Requirements”. </b>However, please note that no website, Internet transmission, computer system or wireless connection is completely secure. In addition, we take reasonable steps to ensure that we retain personal information about you only for so long as is necessary for the purpose for which it was collected, or as required under any contract or by applicable law.</span>
              </ul>
              <ul>IX. How long we retain personal data.<br/>
                <span>We will retain your personal information as long as necessary or permitted in light of the purposes outlined in this Statement and consistent with applicable law. These criteria used to determine our retention periods include:</span>
                <li>The length of time we have an ongoing relationship with you and provide the services to you (for example, for as long as you use a Progear Automotive vehicle/services)</li>
                <li>Whether there is a legal obligation to which we are subject (for example, certain laws require us to keep records of your transactions for a certain period of time before we can delete them); or</li>
                <li>Whether retention is advisable in light of our legal position (such as in regard to applicable statues of limitations, litigation or regulatory investigations).</li>
                <li>In addition, we take reasonable steps to ensure that we retain personal information about you only for so long as is necessary for the purpose for which it was collected, or as required under any contract or by applicable law.</li>
              </ul>
              <ul>X. Changes to this privacy policy.<br/>
                <span>We reserve the right to amend this Privacy Policy from time to time to reflect changes in the law, our data collection and use practices, the features of our services, or advances in technology. Please check this page periodically for changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is used. If we make any material changes to this Privacy Policy, we will post the changes here. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will constitute your consent and acceptance of those changes.</span>
              </ul>
              <ul>XI. Contact Us.<br/>
                <span>If you have any queries relating to the processing/usage of information provided by you or Progear Automotive’s Privacy Policy, you may email us at <span className='contact' onClick={handleEmailClick}>support@progearautomotive.com</span> or contact us at <span className='contact' onClick={handleContactClick}>+91 9094008000.</span></span>
              </ul>
              <ul>XII. Grievance redressal.<br/>
                <span>If you have any questions about this Policy or other privacy concerns, you can also email us at <span className='contact' onClick={handleEmailClick}>support@progearautomotive.com</span></span>
              </ul>
              <ul>XIII. Governing Law.<br/>
                <span>If you choose to visit the Site, your visit and any dispute over privacy are subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Privacy Policy shall be governed by the laws of India.</span>
              </ul>
            </span>
];
