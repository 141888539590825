import React, { memo } from 'react'
import './LeaseModel.scss';
import OwnCar from '../../Assests/CarOwnIcon.svg';
import LeaseCar from '../../Assests/LeaseCarIcon.svg';
import BTN from '../BTN/BTN';
import UpgradeCars from '../../Assests/UpgradeCars.svg';

const LeaseModel = memo(() => {
  const handleClick = () => {
    window.location.href = 'tel:+919094008000';
  };
  return (
    <>
        <section>
            <div className='leasemodel'>
              <div className='leasemodelcontent'>
                <span className='leasemodelheading'>Our Corporate Lease Models</span>
                <span className='leasemodeltext'>Exclusively designed for corporate mobility</span>
              </div>
              <div className='leasemodelcards'>
                <div className='card'>
                  <div className='imgdiv'><img src={OwnCar} alt='Pre-owned cars'/></div>
                    <span className='cardtitle'>Pre-owned cars</span>
                    <span className='cardcontent'>Helping your employees to fulfill the car dreams and save taxes upto 30%.</span>
                    <div onClick={handleClick}><BTN buttonText="Know More" /></div>
                </div>
                <div className='card'>
                  <div className='imgdiv'><img src={LeaseCar} alt='Lease your own car'/></div>
                    <span className='cardtitle'>Lease your own car</span>
                    <span className='cardcontent'>Unlock hidden savings and reduce your employee’s tax burden by up to 30% on their existing car.</span>
                    <div onClick={handleClick}><BTN buttonText="Know More" /></div>
                </div>
                <div className='card'>
                  <div className='imgdiv'><img src={UpgradeCars} alt='Upgrade the car'/></div>
                    <span className='cardtitle'>Upgrade the car</span>
                    <span className='cardcontent'>Simply your employee’s need to sell their existing car to progear at market value to lease their desired car.</span>
                    <div onClick={handleClick}><BTN buttonText="Know More" /></div>
                </div>
              </div>
            </div>
        </section>
    </>
  )
})

export default LeaseModel