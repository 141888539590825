import React, { memo, useState, useEffect } from 'react';
import './MVV2.scss';
import { content } from '../MVV/MVV';

const MissionVisionValue = memo(() => {
    const [filter, setFilter] = useState('MISSION');
    const [selectedSpan, setSelectedSpan] = useState('MISSION');
    const [filteredContent, setFilteredContent] = useState(content.filter(item => item.type === filter).map(item => ({ ...item, collapsed: true })));

    const handleSpanClick = (selectedFilter) => {
        setFilter(selectedFilter);
        setSelectedSpan(selectedFilter);
        setFilteredContent(content.filter(item => item.type === selectedFilter).map(item => ({ ...item, collapsed: true })));
    };

    const toggleContent = (item) => {
        setFilteredContent(
            filteredContent.map(contentItem =>
                contentItem.title === item.title
                    ? { ...contentItem, collapsed: !contentItem.collapsed }
                    : { ...contentItem, collapsed: true }
            )
        );
    };

    return (
        <>
            <section className='outset'>
                <div className='outsetleft'>
                    <div className='outset1'>
                        <span className='border'></span>
                        <div className='outset2'>
                            <span
                                className={`leftcontent ${selectedSpan === 'MISSION' ? 'active' : ''}`}
                                onClick={() => handleSpanClick('MISSION')}
                            >
                                MISSION
                            </span>
                            <span
                                className={`leftcontent ${selectedSpan === 'VISION' ? 'active' : ''}`}
                                onClick={() => handleSpanClick('VISION')}
                            >
                                VISION
                            </span>
                            <span
                                className={`leftcontent ${selectedSpan === 'VALUE' ? 'active' : ''}`}
                                onClick={() => handleSpanClick('VALUE')}
                            >
                                VALUE
                            </span>
                        </div>
                    </div>
                </div>
                <div className='outsetright' style={{ display: 'flex', flexDirection: 'column' }}>
                    {filteredContent.map(item => (
                        <div className={`outsetcontent ${item.collapsed ? 'collapsed' : ''}`} key={item.title}>
                            <span className='title' onClick={() => toggleContent(item)}>{item.title}</span>
                            <span className='description'>{item.description}</span>
                        </div>
                    ))}
                </div>
            </section>
            <section className='outset_md'>
                <div className='outsetcontent_md'>
                    {content.map(item => (
                        <div className='content_md' key={item.type}>
                            <span className='title'>{item.type}</span>
                            <div className='subDescription'>
                                <span className='quotes'>{item.title}</span>
                                <span className='note'>{item.description}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
});

export default MissionVisionValue;


// mobile view 590px