import React from 'react';
import { FaCheck } from "react-icons/fa";

export const offerFeatures1Table = [
    { id: 1, feature: 'Market Price', pros: "₹10,00,000", cons: "₹10,00,000" },
    { id: 2, feature: 'Down payment (20%)', pros: "-", cons: "₹2,00,000" },
    { id: 3, feature: 'EMI (monthly)', pros: "-", cons: "₹36,182" },
    { id: 4, feature: 'Opportunity Cost on Down Payment (10%)', pros: "-", cons: "₹40,000" },
    { id: 5, feature: 'Insurance (ZERO DEP + Hydrostatic Cover)', pros: "-", cons: "₹40,000" },
    { id: 6, feature: 'Service & Maintenance', pros: "-", cons: "₹30,000" },
    { id: 7, feature: 'Resale Value', pros: "-", cons: "₹7,00,000" },
    { id: 8, feature: 'GST', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaCheck style={{ color: "#3DA543" }} /> },
    { id: 9, feature: 'Monthly Lease Cost', pros: "₹35,400", cons: "-" },
    { id: 10, feature: 'Tax Benefit (30%)', pros: "₹10620", cons: "-" },
    { id: 11, feature: 'Driver & Fuel Allowance Tax Benefit', pros: "₹12,000", cons: "-" },
    { id: 12, feature: 'Monthly Cost', pros: "₹12,780", cons: "₹53,265" },
]
export const offerFeatures2Table = [
    { id: 1, feature: 'Market Price', pros: "₹15,00,000", cons: "₹15,00,000" },
    { id: 2, feature: 'Down payment (20%)', pros: "-", cons: "₹3,00,000" },
    { id: 3, feature: 'EMI (monthly)', pros: "-", cons: "₹54,273" },
    { id: 4, feature: 'Opportunity Cost on Down Payment (10%)', pros: "-", cons: "₹60,000" },
    { id: 5, feature: 'Insurance (ZERO DEP + Hydrostatic Cover)', pros: "-", cons: "₹50,000" },
    { id: 6, feature: 'Service & Maintenance', pros: "-", cons: "₹35,000" },
    { id: 7, feature: 'Resale Value', pros: "-", cons: "₹10,50,000" },
    { id: 8, feature: 'GST', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaCheck style={{ color: "#3DA543" }} /> },
    { id: 9, feature: 'Monthly Lease Cost', pros: "₹41,300", cons: "-" },
    { id: 10, feature: 'Tax Benefit (30%)', pros: "₹12,390", cons: "-" },
    { id: 11, feature: 'Driver & Fuel Allowance Tax Benefit', pros: "₹12,000", cons: "-" },
    { id: 12, feature: 'Monthly Cost', pros: "₹16,910", cons: "₹79,065" },
];
export const offerFeatures3Table = [
    { id: 1, feature: 'Market Price', pros: "₹20,00,000", cons: "₹20,00,000" },
    { id: 2, feature: 'Down payment (20%)', pros: "-", cons: '₹4,00,000' },
    { id: 3, feature: 'EMI (monthly)', pros: "-", cons: '₹72,364'},
    { id: 4, feature: 'Opportunity Cost on Down Payment (10%)', pros: "-", cons: '₹80,000'},
    { id: 5, feature: 'Insurance (ZERO DEP + Hydrostatic Cover)', pros: "-", cons: '₹60,000' },
    { id: 6, feature: 'Service & Maintenance', pros: "-", cons: '₹40,000' },
    { id: 7, feature: 'Resale Value', pros: "-", cons: '₹14,00,000' },
    { id: 8, feature: 'GST', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaCheck style={{ color: "#3DA543" }} /> },
    { id: 9, feature: 'Monthly Lease Cost', pros:'₹53,100', cons: "-" },
    { id: 10, feature: 'Tax Benefit (30%)', pros: '₹15,930', cons: "-" },
    { id: 11, feature: 'Driver & Fuel Allowance Tax Benefit', pros: '₹15,000', cons: "-" },
    { id: 12, feature: 'Monthly Cost', pros: '₹22,170', cons: '₹1,04,864' },
];
export const offerFeatures4Table = [
    { id: 1, feature: 'Market Price', pros: "₹25,00,000", cons: "₹25,00,000" },
    { id: 2, feature: 'Down payment (20%)', pros: "-", cons: "₹5,00,000" },
    { id: 3, feature: 'EMI (monthly)', pros: "-", cons: "₹90,455" },
    { id: 4, feature: 'Opportunity Cost on Down Payment (10%)', pros: "-", cons: "₹1,00,000" },
    { id: 5, feature: 'Insurance (ZERO DEP + Hydrostatic Cover)', pros: "-", cons: "₹70,000" },
    { id: 6, feature: 'Service & Maintenance', pros: "-", cons: "₹45,000" },
    { id: 7, feature: 'Resale Value', pros: "-", cons: "₹17,50,000" },
    { id: 8, feature: 'GST', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaCheck style={{ color: "#3DA543" }} /> },
    { id: 9, feature: 'Monthly Lease Cost', pros: "₹64,900", cons: "-" },
    { id: 10, feature: 'Tax Benefit (30%)', pros: "₹19,470", cons: "-" },
    { id: 11, feature: 'Driver & Fuel Allowance Tax Benefit', pros: "₹15,000", cons: "-" },
    { id: 12, feature: 'Monthly Cost', pros: "₹30,430", cons: "₹1,30,663" },
];
export const offerFeatures5Table = [
    { id: 1, feature: 'Market Price', pros: "₹35,00,000", cons: "₹35,00,000" },
    { id: 2, feature: 'Down payment (20%)', pros: "-", cons: "₹7,00,000" },
    { id: 3, feature: 'EMI (monthly)', pros: "-", cons: "₹1,26,636" },
    { id: 4, feature: 'Opportunity Cost on Down Payment (10%)', pros: "-", cons: "₹1,40,000" },
    { id: 5, feature: 'Insurance (ZERO DEP + Hydrostatic Cover)', pros: "-", cons: "₹1,00,000" },
    { id: 6, feature: 'Service & Maintenance', pros: "-", cons: "₹50,000" },
    { id: 7, feature: 'Resale Value', pros: "-", cons: "₹24,50,000" },
    { id: 8, feature: 'GST', pros: <FaCheck style={{ color: "#3DA543" }} />, cons: <FaCheck style={{ color: "#3DA543" }} /> },
    { id: 9, feature: 'Monthly Lease Cost', pros: "₹88,500", cons: "-" },
    { id: 10, feature: 'Tax Benefit (30%)', pros: "₹26,550", cons: "-" },
    { id: 11, feature: 'Driver & Fuel Allowance Tax Benefit', pros: "₹15,000", cons: "-" },
    { id: 12, feature: 'Monthly Cost', pros: "₹46,950", cons: "₹1,82,469" },
];

