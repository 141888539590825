export const accordionData = [
    {
        title: 'What is ProGear lease/subscription model?',
        content: `It's a new method of owning new and pre-owned cars of your choice, without having to worry about a down payment, bank debt, vehicle registration, insurance, upkeep, or servicing for a specific period. These are all packaged together and offered to you as a complete package.`,
    },
    {
        title: 'What are the benefits of ProGear car subscription program?',
        content:
            <ul>
                <li>No depreciation risks</li>
                <li>No maintenance and service issue</li>
                <li>No insurance and tax pay</li>
                <li>Flexible tenures</li>
                <li>Stay up with the latest cars</li>
            </ul>,
    },
    {
        title: 'How ProGear subscription is different from car rental or self drive car?',
        content: `Unlike self-drive services where a car with a yellow or black license plate, ProGear gives you access to a refurbished car with a white number plate. Here, you may easily drive the vehicle for the chosen period of time and, at the end, return or upgrade the vehicle, or extend  the term. `,
    },
    {
        title: 'What is the eligibility criteria for ProGear subscription?',
        content: `Eligibility criteria for ProGear subscription is based on the credit score, KYC verification and income verification(6-month bank statements, Credit check, Salary slip etc).`,
    },
    {
        title: 'What type of registration will the car have?',
        content: `We exclusively offer cars equipped with white boards.Change to-`,
    },
    {
        title: 'Who will be the registered owner of the car?',
        content: `The registrated owner of the car would be in the name of the company or finance partner.`,
    },
]