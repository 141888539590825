import React from 'react';
import './General.scss';
const GeneralTerms = ({ title, details }) => {
  return (
    <section>
      <div className='Policy'>
        <div className='PolicyContainor'>
          <span className='PolicyTitle'>{title} <hr /></span>
          <div className='PolicyDetails'>
            {details.map((detail, index) => (
              <span key={index}>{detail}</span>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralTerms;
