import React, { useState, memo } from 'react';
import './FAQ.scss'

const FAQ = memo(({ faqsTitle, accordionData }) => {
  const Accordion = ({ title, content, isActive, onClick }) => (
    <div className="accordion-item">
      <div className="accordion-title" onClick={onClick}>
        <div>{title}</div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  if (!accordionData) {
    return null;
  }

  return (
    <section>
      <div className="FAQ">
        <div className='FQAContent'>
          <span className="FAQTitle">{faqsTitle}</span>
          <div className='FAQDetails'>
            <div className='FAQDetailscontent'>
              <div className="accordion-container">
                <div className="accordion">
                  {accordionData.map((item, index) => (
                    <Accordion
                      key={index}
                      title={item.title}
                      content={item.content}
                      isActive={index === activeIndex}
                      onClick={() => handleAccordionClick(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <span className="ForMore">For more clarified details kindly visit our <a href='/faq' target="_blank" >Retail</a> and <a href='/corporate-faq' target="_blank" >Corporate</a> FAQ's page</span>
        </div>
      </div>
    </section>
  );
});

export default FAQ;
