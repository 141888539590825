import React, { memo } from 'react'
import './BecomePartner.scss'
const BecomePartner = memo(() => {
  return (
    <>
        <section>
            <div className='BecomePartner'>
                <div className='Content'>
                    <span className='title'>3 simple steps to become our partner</span>
                    <span className='text'>We offer a simple and transparent onboarding process, unlike a typical traditional lease company</span>
                </div>
                <div className='stepsdiv'>
                <div className='steps'>
                        <div className='rightdiv'><span className='rightdivtext'>1</span></div>
                        <span className='text'>Share your company details and requirements </span>
                    </div>
                    <div className='steps'>
                        <div className='rightdiv'><span className='rightdivtext'>2</span></div>
                        <span className='text'>Verify and sign a lease agreement</span>
                    </div>
                    <div className='steps'>
                        <div className='rightdiv'><span className='rightdivtext'>3</span></div>
                        <span className='text'>Let the employees start subscribing</span>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
})

export default BecomePartner