import React from 'react';
import offerFeatures from '../Savemoney/offerFeature.js';
import '../Savemoney/Table.css';
const OfferTable = () => {
    return (
        <div className='tableContainar'>
            <table className='table'>
                <thead>
                    <tr>
                        <th className='feature'></th>
                        <th className='pros'>ProGear Subscription</th>
                        <th className='cons'>Buy</th>
                    </tr>
                </thead>
                <tbody>
                    {offerFeatures.map(feature => (
                        <tr key={feature.id}>
                            <td className='leftalign'>{feature.feature}</td>
                            <td>{feature.pros}</td>
                            <td>{feature.cons}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OfferTable;