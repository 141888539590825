import React, { useState } from 'react';
import './Form.scss';
import BTN from '../BTN/BTN';
import { RxCross2 } from "react-icons/rx";
import ReCAPTCHA from "react-google-recaptcha";

const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const ContactForm = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        interestedIn: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [captcha, setCaptcha] = useState('');

    const handleChange = (e) => {
        const { id, value } = e.target;
        let errors = { ...formErrors };

        switch (id) {
            case 'fullName':
                errors.fullName = value.trim() ? (/^[a-zA-Z\s]+$/.test(value) ? '' : 'Name should contain only alphabetic characters') : 'This field is required';
                break;
            case 'email':
                errors.email = value.trim() ? (isValidEmail(value) ? '' : 'Invalid email address') : 'This field is required';
                break;
            case 'mobileNumber':
                errors.mobileNumber = value.trim() ? (/^\d{10}$/.test(value) ? '' : 'Mobile number must be 10 digits') : 'This field is required';
                break;
            case 'message':
                errors.message = value.trim() ? (value.trim().split(/\s+/).length <= 200 ? '' : 'Message should be within 200 words') : 'This field is required';
                break;
            case 'interestedIn':
                errors.interestedIn = value ? '' : 'Please select an option';
                break;
            default:
                break;
        }

        setFormData({ ...formData, [id]: value });
        setFormErrors(errors);
    };

    const handleCaptchaChange = (value) => {
        setCaptcha(value);
    };

    const fieldLabels = {
        fullName: 'Full Name',
        email: 'Email Address',
        mobileNumber: 'Mobile Number',
        interestedIn: 'Interested In',
        message: 'Message',
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        const requiredFields = ['fullName', 'email', 'mobileNumber', 'interestedIn', 'message'];
        const emptyField = requiredFields.find(field => !formData[field]);

        if (emptyField || !captcha) {
            const errorMessages = {};
            requiredFields.forEach(field => {
                errorMessages[field] = formData[field] ? '' : `Please fill in the "${fieldLabels[field]}" field.`;
            });
            errorMessages.captcha = !captcha ? 'Please verify you are not a robot.' : '';
            setFormErrors(errorMessages);
            setSubmitting(false);
            return;
        }

        try {
            const response = await fetch('http://localhost:8000/send-email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            console.log(formData);
            console.log('Response status:', response.status);
            if (!response.ok) {
                throw new Error('Failed to send email');
            }

            const data = await response.json();
            console.log("await data", data);

            if (data && data.success) {
                setFormData({
                    fullName: '',
                    email: '',
                    mobileNumber: '',
                    interestedIn: '',
                    message: '',
                });
                setFormErrors({ submit: 'Thank You, your Email has been sent. Our Team will reach out to you shortly.' });
            } else {
                setFormErrors(data.errors || { submit: 'Email is sent without fields!' });
            }
        } catch (error) {
            console.error('Error:', error);
            setFormErrors({ submit: 'Sorry for the inconvenience, Something Went Wrong, Please try again later.' });
        } finally {
            setSubmitting(false);
            setShowPopup(true);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const { fullName, email, mobileNumber, interestedIn, message } = formData;

    return (
        <>
            <div className={`contact-form ${showPopup ? 'blurred' : ''}`}>
                <form onSubmit={handleSubmit}>
                    <span className="form-row">Send us your queries!</span>
                    <div className="form-row">
                        <div className="form-col">
                            <label htmlFor="fullName">Full Name</label>
                            <input
                                type="text"
                                id="fullName"
                                value={fullName}
                                placeholder="Enter your Full Name"
                                onChange={handleChange}
                            />
                            {formErrors.fullName && <p className="error">{formErrors.fullName}</p>}
                        </div>
                        <div className="form-col">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder="Enter your Email Address"
                                onChange={handleChange}
                            />
                            {formErrors.email && <p className="error">{formErrors.email}</p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label htmlFor="mobileNumber">Mobile Number</label>
                            <input
                                type="tel"
                                id="mobileNumber"
                                value={mobileNumber}
                                placeholder="Enter your mobile number"
                                onChange={handleChange}
                            />
                            {formErrors.mobileNumber && <p className="error">{formErrors.mobileNumber}</p>}
                        </div>
                        <div className="form-col">
                            <label htmlFor="interestedIn">Interested In</label>
                            <select id="interestedIn" value={interestedIn} onChange={handleChange}>
                                <option value="">Select an option</option>
                                <option value="option1 - Short-Time Tenure(3 to 6 Month)">Short-Time Tenure(3 to 6 Month)</option>
                                <option value="option2 - Long-Time Tenure (&gt;= 6 Month)">Long-Time Tenure (&gt;= 6 Month)</option>
                            </select>
                            {formErrors.interestedIn && <p className="error">{formErrors.interestedIn}</p>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                value={message}
                                onChange={handleChange}
                                placeholder='Message'
                            ></textarea>
                            {formErrors.message && <p className="error">{formErrors.message}</p>}
                        </div>
                    </div>
                    <div className="form-row captch">
                        <div className='form-col'>
                            <ReCAPTCHA
                                sitekey="6LdCDaopAAAAAGU5rZwlErm-QzRzyRWEbOKCwNeB"
                                onChange={handleCaptchaChange}
                                className='captch' 
                            />
                            {formErrors.captcha && <p className="error">{formErrors.captcha}</p>}
                        </div>
                    </div>
                    <div className='form-row'>
                        <BTN onClick={submitting} buttonText="Submit" />
                        {submitting && (
                            <span>Loading...</span>
                        )}
                    </div>
                </form>
            </div>
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <div className='form-row'>
                            {formErrors.submit ? (
                                <p className="error">{formErrors.submit}</p>
                            ) : (
                                <p className="success">{formErrors.submit}</p>
                            )}
                            <button onClick={closePopup}><RxCross2 /></button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactForm;
