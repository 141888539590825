import React, { memo, useState } from 'react';
import '../Savemoney/Savemoney.scss';
import OfferTable from './LeaseOfferTable';

const ProGearLease = memo(({ text1, text2, text3 }) => {
  const [activeTable, setActiveTable] = useState(1);
  const [saveUptoData, setSaveUptoData] = useState({
    1: 1000,
    2: 2000,
    3: 3000,
    4: 4000,
    5: 5000
});

  return (
    <>
      <section>
        <div className='Savemoney-sec'>
          <div className='Savemoneylayout'>
            <span className='Savemoneytext'>{text1}</span>
            <span className='Savemoneytitle'>{text2}</span>
            <span className='SavemoneyContent'>{text3}</span>
          </div>
          <div className='TableFilter'>
            <span className={activeTable === 1 ? 'active' : ''} onClick={() => setActiveTable(1)}>Hatchback</span>
            <span className={activeTable === 2 ? 'active' : ''} onClick={() => setActiveTable(2)}>Sedan & Compact SUV</span>
            <span className={activeTable === 3 ? 'active' : ''} onClick={() => setActiveTable(3)}>SUV</span>
            <span className={activeTable === 4 ? 'active' : ''} onClick={() => setActiveTable(4)}>MUV</span>
            <span className={activeTable === 5 ? 'active' : ''} onClick={() => setActiveTable(5)}>Luxury</span>
          </div>
          <div className='Table'>
            {activeTable && <OfferTable tableId={activeTable} saveUpto={saveUptoData[activeTable]} />}
          </div>
          {/* <h3 style={{ color: "Black" }}>Save Upto  ${saveUptoData[activeTable]}</h3> */}
        </div>
      </section>
    </>
  )
});

export default ProGearLease;
