import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar.jsx';
import Home from './Pages/Home/Home.jsx';
import FAQPage from './Pages/FAQ/FAQPage.jsx';
import Terms from './Pages/Terms/Terms.jsx';
import Footer from './Components/Footer/Footer.jsx';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy.jsx';
import CorporateFAQ from './Pages/CorporateFAQ/CorporateFAQ.jsx';
import CorporateLease from './Pages/CorporateLease/CorporateLease.jsx';
import ContactUs from './Pages/ContactUs/ContactUs.jsx'
import AboutUs from './Pages/AboutUs/AboutUs.jsx';
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/corporate-faq" element={<CorporateFAQ />} />
          <Route path="/corporate-lease" element={<CorporateLease />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
