import React, { memo } from 'react';
import './Savemoney.scss';
import Table from './Table';

const Savemoney = memo(({ text1, text2, text3 }) => {
  return (
    <>
      <section>
        <div className='Savemoney-sec'>
          <div className='Savemoneylayout'>
            <span className='Savemoneytext'>{text1}</span>
            <span className='Savemoneytitle'>{text2}</span>
            <span className='SavemoneyContent'>{text3}</span>
          </div>
          <div className='Table'>
            <Table />
          </div>
        </div>
      </section>
    </>
  )
});

export default Savemoney;
